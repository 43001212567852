<script>
  import { Container, Image, Figure } from "sveltestrap";
  let breed = "Whippet";
  async function getDogs() {
    const url =
      "https://dog.ceo/api/breed/" + `${breed.toLowerCase()}/images/random/1`;
    const res = await fetch(url);
    if (!res.ok || res.status === 404) return [];
    const json = await res.json();
    return json.message;
  }
  let dogsPromise = getDogs();
</script>

<!-- 
<label>Breeds <input bind:value={breed} /></label>
<button on:click={() => (dogsPromise = getDogs())}> Get Image</button>

{#await dogsPromise}
  <div>Waiting for dogs</div>
{:then imgs}
  {#each imgs as url}
    <div>
      <img alt="dog" src={url} />
    </div>
  {:else}
    <div>Not found</div>
  {/each}
{:catch error}
  <div>
    Error: {error.message}
  </div>
{/await} -->

<Container fluid>
  <!-- svelte-ignore a11y-missing-attribute -->
  <!-- <img class="image" src="https://source.unsplash.com/user/erondu/1600x900" /> -->
  <Image
    fluid
    alt="Landscape"
    src="https://source.unsplash.com/random/1600x900"
  />
  <div class="centered">Kreetha.com <br />coming soon.</div>
</Container>

<style>
  .centered {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 30px;
  }
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
    color: black;
  }
</style>
